<template lang="pug">
  .pagination
    Paginate(
      :page-count="count"
      :page-range="5"
      :click-handler="clickCallback"
      :prev-text="''"
      :next-text="''"
      prev-class="pagination__prev"
      next-class="pagination__next"
      container-class="pagination__list"
      page-class="pagination__item"
      v-model="initial_page"
    )
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  name: 'Pagination',
  components: { Paginate },
  props: {
    count: {
      type: Number,
      required: true
    },
    initial: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      initial_page: 1
    }
  },
  methods: {
    clickCallback (data) {
      this.$emit('page', data)
    },
  },
  watch: {
    initial (data) {
      this.initial_page = Number(data)
    }
  },
  updated () {
    this.initial_page = this.initial
  },
  mounted () {
    this.initial_page = this.initial
  }
}
</script>
