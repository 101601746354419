<template lang="pug">
  .news
    Breadcrumb(:current_path="{name: 'News', title: 'News'}")

    .container.news__container
      h1.title_2.news__title(v-html="$t('pages.news.title')")

      .news__content
        .news__list(v-if="news.length")
          NewsItem(
            v-for="(item, index) in news"
            :key="'news-item__' + index"
            :news="item"
          )
        p(v-if="!news.length && !loader").news__empty {{ $t('pages.news.empty-list') }}
        Loader(v-if="loader").news__loader
        Pagination(v-show="news.length && page_count > 1" :count="page_count" :initial="initial_page" @page="setOptionsPagination")
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb'
import NewsItem from '@/components/common/NewsItem'
import Loader from '@/components/Loader'
import Pagination from '@/components/common/Pagination'
import { mapState } from 'vuex'
import NewsService from '@/services/news.service'

export default {
  name: 'News',
  components: { Loader, NewsItem, Breadcrumb, Pagination },
  data () {
    return {
      loader: true,
      page_count: 1,
      initial_page: 1,
      options: {
        offset: 0,
        limit: 6
      }
    }
  },
  computed: {
    ...mapState({
      news: state => state.news.news
    })
  },
  watch: {
    '$route' (data) {
      if (!data.query.page) {
        this.setInitialPage()
      }
      this.checkQuery(data)
      this.getNews()
    }
  },
  methods: {
    async getNews () {
      this.loader = true
      this.$store.commit('news/setNews', [])

      await NewsService.getNews({ ...this.options })
          .then(response => {
            this.$store.commit('news/setNews', response.news)
            this.page_count = Math.round(Math.ceil(response.count / this.options.limit))
            this.loader = false
          })
    },
    setOptionsPagination (page) {
      this.options.offset = this.options.limit * (page - 1)
      this.$router.replace({ query: { ...this.$route.query, page } }, () => {
      })
    },
    checkQuery (data) {
      if (data.query.page) {
        this.setInitialPage(data.query.page)
      }
    },
    setInitialPage (page = 1) {
      this.initial_page = Number(page)
      this.setOptionsPagination(page)
    },
  },
  mounted () {
    this.checkQuery(this.$route)
    this.getNews()
  }
}
</script>
