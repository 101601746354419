<template lang="pug">
  .news-item
    .news-item__block
      router-link(:to="$i18nRoute({ name: 'NewsCard', params: { id: news.id }})").news-item__image
        BaseImage(
          :path="news.banner"
          :alt="news.head"
          external
        )
      .news-item__date {{ news.created_at | formatDate('short') }}
    .news-item__content
      h3.news-item__title(v-html="news.head")
      p.news-item__description(v-html="news.text")
</template>

<script>
import BaseImage from '@/components/common/BaseImage'

export default {
  name: 'NewsItem',
  components: { BaseImage },
  props: {
    news: {
      type: Object,
      default: null
    }
  }
}
</script>
