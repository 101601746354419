<template lang="pug">
  .loader
    .loader__border
      .loader__core
</template>

<script>
export default {
  name: 'Loader'
}
</script>
